<script lang="ts" setup>
import { computed, watchEffect, ref } from 'vue';
import {
    VRow,
    VCol,
    VSheet,
    VCard,
    VCardTitle,
    VCardText,
    VTextField,
    VSelect,
    VTextarea,
    VForm,
    VAutocomplete,
} from 'vuetify/components';
import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/gql';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useRouteParams } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { toTypedSchema } from '@vee-validate/zod';
import { useField, useForm } from 'vee-validate';
import { requirements } from '@/utils/formRequirements';
import * as zod from 'zod';
import { VDateInput } from 'vuetify/labs/VDateInput';
import {
    Direction,
    Operator,
    type Department,
    type Program,
    type TagValue,
    type Theme,
    type UpdateProjectInput,
    type User,
} from '@/gql/graphql';
import { usePagination } from '@/composables/pagination';
import { useSorting } from '@/composables/sorting';
import { watchDebounced } from '@vueuse/core';
import { useDateTime } from '@/composables/datetime';
import { RoleName } from '@/constants/role';
import { PolicyAction } from '@/types';
import { isNotUndefined } from '@/utils';
import { usePermissions } from '@/composables/permissions';

type SimpleTagValue = Pick<TagValue, 'id' | 'name'>;
type SimpleTheme = Pick<Theme, 'id' | 'name'>;
type SimpleDepartment = Pick<Department, 'id' | 'name'>;
type SimpleProgram = Pick<Program, 'id' | 'name'>;
interface SimpleUser
    extends Pick<User, 'id' | 'firstName' | 'lastName' | 'fullName'> {
    name: string;
}

const props = withDefaults(
    defineProps<{
        isSubmitting: boolean;
    }>(),
    {
        isSubmitting: false,
    }
);

const emit = defineEmits<{
    (e: 'submit', values: UpdateProjectInput): void;
    (e: 'close'): void;
}>();

const { t } = useI18n({
    messages: {
        nl: {
            label: {
                abbreviation: '@:abbreviation',
                department: '@:department',
                description: 'Probleemomschrijving',
                end_date: '@:end_date',
                goal: '@:goal',
                name: '@:name',
                portfolio: '@:master_portfolio',
                program: '@:program',
                program_manager: '@:program_manager',
                project_manager: '@:project_manager',
                project_team: 'Team',
                result: 'Resultaat',
                risks: "Risico's en maatregelen",
                scope: 'Scope',
                stakeholders: 'Stakeholders',
                start_date: '@:start_date',
                tags: '@:tags',
                theme: '@:theme',
                focus: '@:focus',
                need: '@:need',
            },
            hint: {
                description:
                    'Beschrijf het probleem. Benoem wie dit probleem ervaart, wanneer het zich voordoet en wat de impact is voor de organisatie.',
                goal: 'Benoem de gewenste uitkomsten in relatie tot de doelstelling van het strategisch thema. Bijvoorbeeld: een kostenreductie, kwaliteitsverbetering of productiviteitsverhoging.',
                result: 'Beschrijf wat er opgeleverd zal worden. Benoem het (tastbare) resultaat',
                risks: "Benoem welke risico's je resultaat of voortgang kunnen hinderen. Beschrijf welke mitigerende acties je kunt ondernemen om de mogelijke impact van deze risico’s te minimaliseren.",
                scope: 'Beschrijf de scope van het project. Benoem zowel wat erbinnen als buiten de scope valt.',
                stakeholders:
                    'Beschrijf welke stakeholders je nodig hebt (intern en extern) en wat je van ze nodig hebt (input, expertise, advies, goedkeuring etc.)',
            },
            subtitle: {
                general: 'Algemeen',
                tags: '@:tags',
                info: 'Projectinformatie',
            },
            portfolio_warning:
                "Enkele velden kunnen alleen bewerkt worden vanuit het @.lower:{'master_portfolio'}: {portfolio}",
            portfolio_name: "@:{'master_portfolio'}: {portfolio}",
        },
        en: {
            label: {
                abbreviation: '@:abbreviation',
                department: '@:department',
                description: 'Problem description',
                end_date: '@:end_date',
                goal: '@:goal',
                name: '@:name',
                portfolio: '@:master_portfolio',
                program: '@:program',
                program_manager: '@:program_manager',
                project_manager: '@:project_manager',
                project_team: 'Team',
                result: 'Result',
                risks: 'Risks and measures',
                scope: 'Scope',
                stakeholders: 'Stakeholders',
                start_date: '@:start_date',
                tags: '@:tags',
                theme: '@:theme',
                focus: '@:focus',
                need: '@:need',
            },
            hint: {
                description:
                    'Describe the problem. Identify who experiences this problem, when it occurs, and the impact on the organization.',
                goal: "Specify the desired outcomes in relation to the strategic theme's objectives. For example: cost reduction, quality improvement, or productivity increase.",
                result: 'Describe what will be delivered. Specify the (tangible) outcome.',
                risks: 'Identify the risks that could hinder your result or progress. Describe the mitigating actions you can take to minimize the possible impact of these risks.',
                scope: 'Describe the scope of the project. Specify both what is within and outside the scope.',
                stakeholders:
                    'Describe which stakeholders you need (internal and external) and what you require from them (input, expertise, advice, approval, etc.).',
            },
            subtitle: {
                general: 'General',
                tags: '@:tags',
                info: 'Project information',
            },
            portfolio_warning:
                "Some fields can only be edited from the @.lower:{'master_portfolio'}: {portfolio}",
            portfolio_name: "@:{'master_portfolio'}: {portfolio}",
        },
    },
});

const userSettings = useUserSettingsStore();
const { hasPermission } = usePermissions();

const activePortfolioId = computed(() => userSettings.activePortfolio?.id);
const accountId = computed(() => userSettings.activeOrganisation?.id);
const projectId = useRouteParams<string>('id');

const variables = computed(() => ({
    projectId: projectId.value,
    portfolioId: activePortfolioId.value ?? '',
}));

const { result: projectQueryResult } = useQuery(
    graphql(`
        query ProjectDetails($projectId: ID!, $portfolioId: ID!) {
            project(projectId: $projectId, portfolioId: $portfolioId)
        }
    `),
    variables,
    {
        fetchPolicy: 'cache-first',
    }
);

const project = computed(() => projectQueryResult?.value?.project);
const isSubproject = computed(() => Boolean(project?.value?.parentProjectId));
const isMasterPortfolioActive = computed(
    () => project.value.masterPortfolio.id === activePortfolioId.value
);
const canMoveProject = hasPermission(
    PolicyAction.PORTFOLIO_WRITE,
    activePortfolioId.value,
    activePortfolioId.value
);
const { project: fields } = requirements;

const validationSchema = toTypedSchema(
    zod.object({
        abbreviation: zod
            .string()
            .min(fields.abbreviation.min, t('error.required_field'))
            .max(
                fields.abbreviation.max,
                t('error.maximum_characters', { max: fields.abbreviation.max })
            ),
        name: zod
            .string()
            .min(fields.name.min, t('error.required_field'))
            .max(
                fields.name.max,
                t('error.maximum_characters', { max: fields.name.max })
            ),
        focus: zod.string(),
        need: zod.string(),
        programManager: zod.string().nullable(),
        projectManager: zod.string().nullable(),
        program: zod.string().nullable(),
        department: zod.string().nullable(),
        theme: zod.string().nullable(),
        startDate: zod.date().nullable(),
        endDate: zod.date().nullable(),
        projectTeam: zod.array(zod.string()).nullable(),
        description: zod.string().nullable(),
        goal: zod.string().nullable(),
        scope: zod.string().nullable(),
        result: zod
            .string()
            .max(
                fields.result.max,
                t('error.maximum_characters', { max: fields.result.max })
            )
            .nullable(),
        risks: zod.string().nullable(),
        stakeholders: zod.string().nullable(),
    })
);

const { handleSubmit, meta } = useForm({
    validationSchema,
    initialValues: {
        abbreviation: project.value.abbreviation,
        name: project.value.name,
        program: project.value?.programs?.[0]?.id ?? null,
        department: project.value?.departments?.[0]?.id ?? null,
        theme: project.value?.themes?.[0]?.id ?? null,
        startDate: project.value.startDate
            ? new Date(project.value.startDate)
            : null,
        endDate: project.value.endDate ? new Date(project.value.endDate) : null,
        programManager: project.value?.programManager?.id ?? null,
        projectManager: project.value?.projectManager?.id ?? null,
        projectTeam: [],
        description: project.value.description,
        goal: project.value.goal,
        scope: project.value.scope,
        risks: project.value.risks,
        result: project.value.result,
        stakeholders: project.value.stakeholders,
        focus: project.value.focus?.id || '',
        need: project.value.need?.id || '',
    },
});

const isValid = computed(() => meta.value.valid);

const name = useField<string>('name');
const abbreviation = useField<string>('abbreviation');
const focus = useField<string>('focus');
const need = useField<string>('need');
const description = useField<string>('description');
const goal = useField<string>('goal');
const scope = useField<string>('scope');
const result = useField<string>('result');
const risks = useField<string>('risks');
const stakeholders = useField<string>('stakeholders');
const startDate = useField<Date | null>('startDate');
const endDate = useField<Date | null>('endDate');
const department = useField<string | null>('department');
const program = useField<string | null>('program');
const theme = useField<string | null>('theme');
const programManager = useField<string | null>('programManager');
const projectManager = useField<string | null>('projectManager');
const projectTeam = useField<string[]>('projectTeam');

const minDate = computed(() => {
    if (!startDate.value.value) {
        return null;
    }

    const newDate = new Date(startDate.value.value);

    newDate.setDate(newDate.getDate() + 1);

    return newDate;
});

const maxDate = computed(() => {
    if (!endDate.value.value) {
        return null;
    }

    const newDate = new Date(endDate.value.value);

    newDate.setDate(newDate.getDate() - 1);

    return newDate;
});

const submit = handleSubmit((values) => {
    const data: UpdateProjectInput = {
        projectId: projectId.value,
        abbreviation: values.abbreviation,
        name: values.name,
        portfolioId: String(activePortfolioId.value),
        description: values.description || null,
        goal: values.goal || null,
        scope: values.scope || null,
        result: values.result || null,
        risks: values.risks || null,
        stakeholders: values.stakeholders || null,
        focusId: values.focus || null,
        needId: values.need || null,
    };

    if (values.startDate) {
        const { formattedDate: startDate } = useDateTime(values.startDate);
        data.startDate = startDate.value;
    }

    if (values.endDate) {
        const { formattedDate: endDate } = useDateTime(values.endDate);
        data.endDate = endDate.value;
    }

    if (values.theme) {
        data.themeId = values.theme;
    }

    if (values.department) {
        data.departmentId = values.department;
    }

    if (values.program) {
        data.programId = values.program;
    }

    if (values.programManager) {
        data.programManagerId = values.programManager;
    }

    if (values.projectManager) {
        data.projectManagerId = values.projectManager;
    }

    data.tags = selectedTagValues.value;

    data.projectTeam = JSON.stringify(
        selectedTeamMembers.value.map((member) => {
            const user = users.value.find((user) => user.id === member.id);

            return {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
            };
        })
    );

    emit('submit', data);
});

watchEffect(() => {
    if (props.isSubmitting) {
        submit();
    }
});

const { pagination } = usePagination({ pageSize: 999 });
const { sorting } = useSorting([{ field: 'name', direction: Direction.ASC }]);
const { sorting: userSorting } = useSorting([
    { field: 'lastName', direction: Direction.ASC },
]);

/** Location */

const axisVariables = computed(() => ({
    portfolioId: activePortfolioId.value ?? '',
}));

const { result: axisResult, loading: axisLoading } = useQuery(
    graphql(`
        query ProjectLocation($portfolioId: ID!) {
            portfolioAxis(portfolioId: $portfolioId) {
                items
            }
        }
    `),
    axisVariables
);

const focusItems = computed(
    () => axisResult?.value?.portfolioAxis.items.x.focus
);
const needsItems = computed(
    () => axisResult?.value?.portfolioAxis.items.y.needs
);

/** Departments */

const searchDepartments = ref('');
const queryStringDepartments = ref('');

const departmentsQueryVariables = computed(() => ({
    filters: [
        {
            field: 'portfolio_id',
            value: activePortfolioId.value,
        },
        {
            field: 'name',
            operator: Operator.ILIKE,
            value: `%${queryStringDepartments.value}%`,
        },
    ],
    sorting: sorting.value,
    pagination: pagination.value,
}));

const { result: departmentsResult, loading: departmentsLoading } = useQuery(
    graphql(`
        query DepartmentsAutocomplete(
            $filters: [FilterCriterion]
            $sorting: [SortingCriterion]
            $pagination: Pagination
        ) {
            departments(
                filters: $filters
                sorting: $sorting
                pagination: $pagination
            ) {
                items {
                    id
                    name
                }
            }
        }
    `),
    departmentsQueryVariables
);

watchDebounced(
    searchDepartments,
    () => {
        queryStringDepartments.value = searchDepartments.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const departmentItems = computed(() => {
    const departments = [
        project.value?.departments?.[0] || null,
        ...(departmentsResult.value?.departments?.items ?? []),
        selectedDepartment.value || null,
    ].filter(Boolean);
    const ids = [...new Set(departments.map((department) => department.id))];
    return ids.map((id) =>
        departments.find((department) => department.id === id)
    );
});

const selectedDepartment = ref<SimpleDepartment | null>(null);

const departmentUpdated = (value: string | null) => {
    selectedDepartment.value = departmentItems.value.find(
        (departmentItem) => value === departmentItem.id
    );
};

const departmentChanged = () => {
    searchDepartments.value = ' ';
    searchDepartments.value = '';
};

/** Programs */

const searchPrograms = ref('');
const queryStringPrograms = ref('');

const programsQueryVariables = computed(() => ({
    filters: [
        {
            field: 'portfolio_id',
            value: activePortfolioId.value,
        },
        {
            field: 'name',
            operator: Operator.ILIKE,
            value: `%${queryStringPrograms.value}%`,
        },
    ],
    sorting: sorting.value,
    pagination: pagination.value,
}));

const { result: programsResult, loading: programsLoading } = useQuery(
    graphql(`
        query ProgramsAutocomplete(
            $filters: [FilterCriterion]
            $sorting: [SortingCriterion]
            $pagination: Pagination
        ) {
            programs(
                filters: $filters
                sorting: $sorting
                pagination: $pagination
            ) {
                items {
                    id
                    name
                }
            }
        }
    `),
    programsQueryVariables
);

watchDebounced(
    searchPrograms,
    () => {
        queryStringPrograms.value = searchPrograms.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const programItems = computed(() => {
    const programs = [
        project.value?.programs?.[0] || null,
        ...(programsResult.value?.programs?.items ?? []),
        selectedProgram.value || null,
    ].filter(Boolean);
    const ids = [...new Set(programs.map((program) => program.id))];
    return ids.map((id) => programs.find((program) => program.id === id));
});

const selectedProgram = ref<SimpleProgram | null>(null);

const programUpdated = (value: string | null) => {
    selectedProgram.value = programItems.value.find(
        (programItem: SimpleProgram) => value === programItem.id
    );
};

const programChanged = () => {
    searchPrograms.value = ' ';
    searchPrograms.value = '';
};

/** Themes */

const searchThemes = ref('');
const queryStringThemes = ref('');

const themesQueryVariables = computed(() => ({
    filters: [
        {
            field: 'portfolio_id',
            value: activePortfolioId.value,
        },
        {
            field: 'name',
            operator: Operator.ILIKE,
            value: `%${queryStringThemes.value}%`,
        },
    ],
    sorting: sorting.value,
    pagination: pagination.value,
}));

const { result: themesResult, loading: themesLoading } = useQuery(
    graphql(`
        query ThemesAutocomplete(
            $filters: [FilterCriterion]
            $sorting: [SortingCriterion]
            $pagination: Pagination
        ) {
            themes(
                filters: $filters
                sorting: $sorting
                pagination: $pagination
            ) {
                items {
                    id
                    name
                }
            }
        }
    `),
    themesQueryVariables
);

watchDebounced(
    searchThemes,
    () => {
        queryStringThemes.value = searchThemes.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const themeItems = computed(() => {
    const themes = [
        project.value?.themes?.[0] || null,
        ...(themesResult.value?.themes?.items ?? []),
        selectedTheme.value || null,
    ].filter(Boolean);
    const ids = [...new Set(themes.map((theme) => theme.id))];
    return ids.map((id) => themes.find((theme) => theme.id === id));
});

const selectedTheme = ref<SimpleTheme | null>(null);

const themeUpdated = (value: string | null) => {
    selectedTheme.value =
        themeItems.value.find(
            (themeItem: SimpleTheme) => value === themeItem.id
        ) ?? null;
};

const themeChanged = () => {
    searchThemes.value = ' ';
    searchThemes.value = '';
};

/** Users */

const queryStringUsers = ref('');

const usersQueryVariables = computed(() => ({
    portfolioId: activePortfolioId.value,
    filters: [
        {
            field: 'access.roles.name',
            value: RoleName.USER,
        },
        {
            field: 'fullName',
            operator: Operator.ILIKE,
            value: `%${queryStringUsers.value}%`,
        },
    ],
    sorting: userSorting.value,
    pagination: pagination.value,
}));

const {
    result: usersResult,
    loading: usersLoading,
    onResult: onUsersResult,
} = useQuery(
    graphql(`
        query UsersAutocomplete(
            $portfolioId: ID
            $filters: [FilterCriterion]
            $sorting: [SortingCriterion]
            $pagination: Pagination
        ) {
            users(
                portfolioId: $portfolioId
                filters: $filters
                sorting: $sorting
                pagination: $pagination
            ) {
                items {
                    id
                    fullName
                    firstName
                    lastName
                    access {
                        roles {
                            name
                            portfolioId
                        }
                    }
                }
            }
        }
    `),
    usersQueryVariables
);

const users = computed(() => usersResult.value?.users?.items ?? []);

// Map the user ids of projectTeam to the actual users.
onUsersResult(() => {
    /** Note: This should really be fixed in the backend.
        The project team is a string right now with 0 validation. The data should look like this after parsing:
        [
            {
                id: 'uuid-uuid-uuid-uuid',
                firstName: 'John',
                lastName: 'Doe'
            },
            {
                id: 'uuid-uuid-uuid-uuid',
                firstName: 'Jane',
                lastName: 'Doe'
            },
            ...
        ]
    */

    // Parse the project team members
    try {
        const team: TeamMember[] = project.value?.projectTeam
            ? JSON.parse(project.value.projectTeam)
            : [];

        projectTeam.setValue(team.map(({ id }) => id));
    } catch {
        // noop
    }
});

/** Program manager */

const searchProgramManagers = ref('');
const queryStringProgramManagers = ref('');

const programManagersQueryVariables = computed(() => ({
    portfolioId: activePortfolioId.value,
    filters: [
        {
            field: 'access.roles.name',
            value: RoleName.PROGRAM_MANAGER,
        },
        {
            field: 'fullName',
            operator: Operator.ILIKE,
            value: `%${queryStringProgramManagers.value}%`,
        },
    ],
    sorting: userSorting.value,
    pagination: pagination.value,
}));

const { result: programManagersResult, loading: programManagersLoading } =
    useQuery(
        graphql(`
            query ProgramManagersAutocomplete(
                $portfolioId: ID
                $filters: [FilterCriterion]
                $sorting: [SortingCriterion]
                $pagination: Pagination
            ) {
                users(
                    portfolioId: $portfolioId
                    filters: $filters
                    sorting: $sorting
                    pagination: $pagination
                ) {
                    items {
                        id
                        fullName
                        firstName
                        lastName
                        access {
                            roles {
                                name
                                portfolioId
                            }
                        }
                    }
                }
            }
        `),
        programManagersQueryVariables
    );

watchDebounced(
    searchProgramManagers,
    () => {
        queryStringProgramManagers.value = searchProgramManagers.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const programManagerItems = computed(() => {
    const initialProgramManager = project.value?.programManager;
    const programManagers = [
        initialProgramManager
            ? {
                  ...initialProgramManager,
                  name: [
                      initialProgramManager.firstName,
                      initialProgramManager.lastName,
                  ].join(' '),
              }
            : null,
        ...(programManagersResult.value?.users?.items ?? []).map((user) => ({
            ...user,
            name: user.fullName,
        })),
        selectedProgramManager.value || null,
    ].filter(Boolean);
    const ids = [
        ...new Set(programManagers.map((programManager) => programManager.id)),
    ];
    return ids.map((id) =>
        programManagers.find((programManager) => programManager.id === id)
    );
});

const selectedProgramManager = ref<SimpleUser | null>(null);

const programManagerUpdated = (value: string | null) => {
    selectedProgramManager.value = programManagerItems.value.find(
        (programManagerItem: SimpleUser) => value === programManagerItem.id
    );
};

/** Project manager */

const searchProjectManagers = ref('');
const queryStringProjectManagers = ref('');

const projectManagersQueryVariables = computed(() => ({
    portfolioId: activePortfolioId.value,
    filters: [
        {
            field: 'access.roles.name',
            value: RoleName.PROJECT_MANAGER,
        },
        {
            field: 'fullName',
            operator: Operator.ILIKE,
            value: `%${queryStringProjectManagers.value}%`,
        },
    ],
    sorting: userSorting.value,
    pagination: pagination.value,
}));

const { result: projectManagersResult, loading: projectManagersLoading } =
    useQuery(
        graphql(`
            query ProjectManagersAutocomplete(
                $portfolioId: ID
                $filters: [FilterCriterion]
                $sorting: [SortingCriterion]
                $pagination: Pagination
            ) {
                users(
                    portfolioId: $portfolioId
                    filters: $filters
                    sorting: $sorting
                    pagination: $pagination
                ) {
                    items {
                        id
                        fullName
                        firstName
                        lastName
                        access {
                            roles {
                                name
                                portfolioId
                            }
                        }
                    }
                }
            }
        `),
        projectManagersQueryVariables
    );

watchDebounced(
    searchProjectManagers,
    () => {
        queryStringUsers.value = searchProjectManagers.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const projectManagerItems = computed(() => {
    const initialProjectManager = project.value?.projectManager;
    const projectManagers = [
        initialProjectManager
            ? {
                  ...initialProjectManager,
                  name: [
                      initialProjectManager.firstName,
                      initialProjectManager.lastName,
                  ].join(' '),
              }
            : null,
        ...(projectManagersResult.value?.users?.items ?? []).map((user) => ({
            ...user,
            name: user.fullName,
        })),
        selectedProjectManager.value || null,
    ].filter(Boolean);
    const ids = [
        ...new Set(projectManagers.map((projectManager) => projectManager.id)),
    ];
    return ids.map((id) =>
        projectManagers.find((projectManager) => projectManager.id === id)
    );
});

const selectedProjectManager = ref<SimpleTheme | null>(null);

const projectManagerUpdated = (value: string | null) => {
    selectedProjectManager.value = projectManagerItems.value.find(
        (projectManagerItem: SimpleUser) => value === projectManagerItem.id
    );
};

const searchTeamMembers = ref('');

watchDebounced(
    searchTeamMembers,
    () => {
        queryStringUsers.value = searchTeamMembers.value;
    },
    { debounce: 500, maxWait: 1000 }
);

const selectedTeamMembers = ref<SimpleUser[]>([]);

const teamMembersUpdated = (value: string[]) => {
    selectedTeamMembers.value = teamMemberItems.value.filter(({ id }) => {
        return value.includes(id);
    });
};

/** Team members */
const teamMemberItems = computed(() => {
    try {
        const team: TeamMember[] = project.value.projectTeam
            ? JSON.parse(project.value.projectTeam)
            : [];

        const users = [
            ...team,
            ...(usersResult.value?.users?.items ?? []),
            ...selectedTeamMembers.value,
        ].map((user) => ({
            ...user,
            name: `${user.firstName} ${user.lastName}`,
            fullName: `${user.firstName} ${user.lastName}`,
        }));

        const ids = [...new Set(users.map((user) => user.id))];

        return ids
            .map((id) => users.find((user) => user.id === id))
            .filter(isNotUndefined);
    } catch {
        const users = [
            ...(usersResult.value?.users?.items ?? []),
            ...selectedTeamMembers.value,
        ].map((user) => ({
            ...user,
            name: user.fullName,
            fullName: user.fullName,
        }));

        const ids = [...new Set(users.map((user) => user.id))];

        return ids
            .map((id) => users.find((user) => user.id === id))
            .filter(isNotUndefined);
    }
});

/** Tags */

const projectTagValues = computed(() => project.value?.tagValues || []);

const tagsQueryVariables = computed(() => ({
    accountId: accountId.value,
    filters: [
        {
            field: 'portfolio_id',
            value: activePortfolioId.value,
        },
    ],
    sorting: sorting.value,
    pagination: pagination.value,
}));

const { result: tagsOptionsResult, loading: tagsOptionsLoading } = useQuery(
    graphql(`
        query TagsOptions(
            $filters: [FilterCriterion]
            $sorting: [SortingCriterion]
        ) {
            tags(filters: $filters, sorting: $sorting) {
                items {
                    id
                    name
                    values {
                        id
                        name
                    }
                }
            }
        }
    `),
    tagsQueryVariables
);

const tagsOptions = computed(() => tagsOptionsResult.value?.tags?.items || []);

const selectedTagValues = ref<string[]>([]);

watchEffect(() => {
    selectedTagValues.value = projectTagValues.value.map(
        (projectTagValue: SimpleTagValue) => projectTagValue.id
    );
});

const tagsUpdated = (tagId: string, newValue: string[]) => {
    const allTagValuesForTag = (
        tagsOptions.value.find(({ id }) => id === tagId)?.values ?? []
    ).map(({ id }) => id);

    selectedTagValues.value = selectedTagValues.value.filter(
        (selectedTagValue) => !allTagValuesForTag.includes(selectedTagValue)
    );

    selectedTagValues.value = [...selectedTagValues.value, ...newValue];
};

defineExpose({ isValid });
</script>

<template>
    <v-form :disabled="isSubmitting">
        <v-sheet color="#FFFFFF" class="pa-5 rounded-xl mt-16">
            <p class="text-body-2 mt-4 mb-6 text-grey">
                {{
                    t('portfolio_name', {
                        portfolio: project.masterPortfolio.name,
                    })
                }}
            </p>
            <p
                v-if="!isMasterPortfolioActive"
                class="text-body-2 mt-4 mb-6 text-warning"
            >
                {{
                    t('portfolio_warning', {
                        portfolio: project.masterPortfolio.name,
                    })
                }}
            </p>
            <v-card class="border mb-5">
                <v-card-title class="text-title-medium">
                    {{ t('subtitle.general') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col md="4">
                            <v-text-field
                                v-model="abbreviation.value.value"
                                name="abbreviation"
                                :label="t('label.abbreviation')"
                                :placeholder="t('label.abbreviation')"
                                :counter="fields.abbreviation.max"
                                :maxlength="fields.abbreviation.max"
                                :error-messages="
                                    abbreviation.errorMessage.value
                                "
                                autofocus
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                :clearable="false"
                            ></v-text-field>
                        </v-col>
                        <v-col md="8">
                            <v-text-field
                                v-model="name.value.value"
                                :placeholder="t('label.name')"
                                :error-messages="name.errorMessage.value"
                                :counter="fields.name.max"
                                :maxlength="fields.name.max"
                                name="name"
                                :label="t('label.name')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                :clearable="false"
                            ></v-text-field>
                        </v-col>
                        <v-col v-if="!isSubproject" md="6">
                            <v-select
                                v-model="focus.value.value"
                                name="focus"
                                :label="t('label.focus')"
                                :placeholder="t('label.focus')"
                                :items="focusItems"
                                :disabled="
                                    axisLoading ||
                                    isSubmitting ||
                                    !canMoveProject
                                "
                                item-title="name"
                                item-value="id"
                            />
                        </v-col>
                        <v-col v-if="!isSubproject" md="6">
                            <v-select
                                v-model="need.value.value"
                                name="need"
                                :label="t('label.need')"
                                :placeholder="t('label.need')"
                                :items="needsItems"
                                :disabled="
                                    axisLoading ||
                                    isSubmitting ||
                                    !canMoveProject
                                "
                                item-title="name"
                                item-value="id"
                            />
                        </v-col>
                        <v-col v-if="!isSubproject" md="4">
                            <v-autocomplete
                                v-model="program.value.value"
                                v-model:search="searchPrograms"
                                :items="programItems"
                                :label="t('label.program')"
                                :loading="programsLoading"
                                name="program"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$program"
                                chips
                                @update:model-value="programUpdated"
                                @click:clear="programChanged"
                            />
                        </v-col>
                        <v-col :md="isSubproject ? 12 : 4">
                            <v-autocomplete
                                v-model="department.value.value"
                                v-model:search="searchDepartments"
                                :items="departmentItems"
                                :label="t('label.department')"
                                :loading="departmentsLoading"
                                name="department"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$department"
                                chips
                                @update:model-value="departmentUpdated"
                                @click:clear="departmentChanged"
                            />
                        </v-col>
                        <v-col v-if="!isSubproject" md="4">
                            <v-autocomplete
                                v-model="theme.value.value"
                                v-model:search="searchThemes"
                                :items="themeItems"
                                :label="t('label.theme')"
                                :loading="themesLoading"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                name="theme"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$theme"
                                chips
                                @update:model-value="themeUpdated"
                                @click:clear="themeChanged"
                            />
                        </v-col>
                        <v-col md="6">
                            <v-date-input
                                v-model="startDate.value.value"
                                :label="t('label.start_date')"
                                :disabled="isSubmitting"
                                :max="maxDate"
                                name="startDate"
                                placeholder="dd-mm-yyyy"
                                variant="outlined"
                                prepend-icon=""
                                prepend-inner-icon="$date"
                                hide-actions
                                clearable
                                readonly
                                @click:clear="startDate.setValue(null)"
                            />
                        </v-col>
                        <v-col md="6">
                            <v-date-input
                                v-model="endDate.value.value"
                                :label="t('label.end_date')"
                                :disabled="isSubmitting"
                                :min="minDate"
                                name="endDate"
                                placeholder="dd-mm-yyyy"
                                variant="outlined"
                                prepend-icon=""
                                prepend-inner-icon="$date"
                                hide-actions
                                clearable
                                readonly
                                @click:clear="endDate.setValue(null)"
                            />
                        </v-col>
                        <v-col md="6">
                            <v-autocomplete
                                v-model="programManager.value.value"
                                v-model:search="searchProgramManagers"
                                :items="programManagerItems"
                                :label="t('label.program_manager')"
                                :loading="programManagersLoading"
                                name="programManager"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$program_manager"
                                chips
                                @update:model-value="programManagerUpdated"
                            />
                        </v-col>
                        <v-col md="6">
                            <v-autocomplete
                                v-model="projectManager.value.value"
                                v-model:search="searchProjectManagers"
                                :items="projectManagerItems"
                                :label="t('label.project_manager')"
                                :loading="projectManagersLoading"
                                name="projectManager"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$project_manager"
                                chips
                                @update:model-value="projectManagerUpdated"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="projectTeam.value.value"
                                v-model:search="searchTeamMembers"
                                :items="teamMemberItems"
                                :label="t('label.project_team')"
                                :loading="usersLoading"
                                name="projectTeam"
                                item-title="fullName"
                                item-value="id"
                                prepend-inner-icon="$team"
                                chips
                                multiple
                                :clearable="false"
                                @update:model-value="teamMembersUpdated"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="border mb-5">
                <v-card-title class="text-title-medium">
                    {{ t('subtitle.tags') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                v-for="tag in tagsOptions"
                                :key="tag.id"
                                :model-value="
                                    selectedTagValues.filter((value: string) =>
                                        tag.values
                                            .map(
                                                (value: SimpleTagValue) =>
                                                    value.id
                                            )
                                            .includes(value)
                                    )
                                "
                                :items="tag.values"
                                :label="tag.name"
                                :loading="tagsOptionsLoading"
                                :name="`tags[${tag.name}]`"
                                :closable-chips="true"
                                item-title="name"
                                item-value="id"
                                prepend-inner-icon="$tag"
                                chips
                                multiple
                                @update:model-value="
                                    (newValue) => tagsUpdated(tag.id, newValue)
                                "
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="border mb-5">
                <v-card-title class="text-title-medium">
                    {{ t('subtitle.info') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="description.value.value"
                                :label="t('label.description')"
                                :error-messages="description.errorMessage.value"
                                :hint="t('hint.description')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="description"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="goal.value.value"
                                :label="t('label.goal')"
                                :error-messages="goal.errorMessage.value"
                                :hint="t('hint.goal')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="goal"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="scope.value.value"
                                :label="t('label.scope')"
                                :error-messages="scope.errorMessage.value"
                                :hint="t('hint.scope')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="scope"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="result.value.value"
                                :label="t('label.result')"
                                :error-messages="result.errorMessage.value"
                                :counter="fields.result.max"
                                :maxlength="fields.result.max"
                                :hint="t('hint.result')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="result"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="risks.value.value"
                                :label="t('label.risks')"
                                :error-messages="risks.errorMessage.value"
                                :hint="t('hint.risks')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="risks"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="stakeholders.value.value"
                                :label="t('label.stakeholders')"
                                :error-messages="
                                    stakeholders.errorMessage.value
                                "
                                :hint="t('hint.stakeholders')"
                                :disabled="
                                    !isMasterPortfolioActive || isSubmitting
                                "
                                persistent-placeholder
                                rows="6"
                                name="stakeholders"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
    </v-form>
</template>

<style lang="scss" scoped>
.v-select {
    :deep(.v-field__input) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}
</style>
