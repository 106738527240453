import { type MaybeRefOrGetter, computed, toValue } from 'vue';
import { add } from 'date-fns';

export function useDateTime(date: MaybeRefOrGetter<Date | null>) {
    const formattedDate = computed(() => {
        const dateValue = toValue(date);
        if (!dateValue) return;

        const offsetDate = add(dateValue, { hours: 12 });
        return offsetDate.toISOString().replace('Z', '');
    });

    return { formattedDate };
}
